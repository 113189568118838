/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import HorizontalAppCard from "examples/Cards/AppCards/HorizontalAppCard";

// Images
import cdAppIcon from "assets/images/appIcons/cd512x512.jpg";
import tsmAppIcon from "assets/images/appIcons/tsm512x512.jpg";

function Apps() {
  return (
    <MKBox display="flex" alignItems="center" borderRadius="xl" my={2} py={2}>
      <Container>
        <Grid container>
          <Grid item xs={12} md={8} sx={{ mb: 6 }}>
            <MKTypography variant="h3" color="dark">
              Apps by Simon Hopkin
            </MKTypography>
            <MKTypography variant="body2" color="dark" opacity={0.8}>
              Just a few of the personal apps I have developed over the years
            </MKTypography>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <MKBox display="flex" mb={1} style={{ height: "100%" }}>
              <HorizontalAppCard
                display="flex"
                image={cdAppIcon}
                name="Chord Detector"
                position={{ color: "dark", label: "iOS" }}
                description="Chord Detector is aimed at musicians of any ability helping learn to play the tracks in your music library by automatically detecting the chords within the track."
                action={{
                  type: "external",
                  route: "/pages/chord-detector",
                  label: "Read more",
                }}
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox display="flex" mb={1} style={{ height: "100%" }}>
              <HorizontalAppCard
                image={tsmAppIcon}
                name="Tempo SlowMo"
                position={{ color: "dark", label: "iOS/Android" }}
                description="Extremely popular too for musicians, dancers and language students.  Slow down oy speed up your music and audio without affecting pitch."
                action={{
                  type: "external",
                  route: "/pages/tempo-slow-mo",
                  label: "Read more",
                }}
              />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Apps;
