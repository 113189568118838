/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// import Apps from "pages/Presentation/sections/Apps";

import HomeFooter from "examples/Footers/HomeFooter";

import footerRoutes from "footer.routes";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";

// Material Kit 2 React components
import MKTypography from "components/MKTypography";

// // Material Kit 2 React examples
// import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// // Author page sections
// import Profile from "pages/LandingPages/Author/sections/Profile";
// import Posts from "pages/LandingPages/Author/sections/Posts";
// import Contact from "pages/LandingPages/Author/sections/Contact";
// import Footer from "pages/LandingPages/Author/sections/Footer";

// // Routes
// import routes from "routes";

// // Images
import featureImage from "assets/images/tsmFeature.png";
import phoneImage from "assets/images/tsmPhone.png";
import appStoreButtonImage from "assets/images/appStoreButtonImage.png";
import playStoreButtonImage from "assets/images/googlePlayButtonImage.png";

function TempoSlowMo() {
  return (
    <>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: 8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) =>
            rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <MKBox
          display="flex"
          alignItems="center"
          borderRadius="xl"
          my={2}
          py={2}
        >
          <Container>
            <Grid container spacing={2} alignItems="center">
              <Grid item md={8} sx={{ mb: 0 }}>
                <MKTypography variant="h2" color="dark">
                  Tempo SlowMo
                </MKTypography>
                <MKTypography variant="h5" color="dark" opacity={0.8}>
                  Practice At Your Own Pace
                </MKTypography>
              </Grid>
              <Grid item md={4} sx={{ mb: 0 }}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item md={6} sx={{ mb: 0 }}>
                    <a href="https://itunes.apple.com/us/app/tempo-slowmo/id460008289?mt=8">
                      <img
                        src={appStoreButtonImage}
                        width="100%"
                        style={{ maxWidth: 200 }}
                      />
                    </a>
                  </Grid>
                  <Grid item md={6} sx={{ mb: 0 }}>
                    <a href="https://play.google.com/store/apps/details?id=com.martianstorm.temposlowmo">
                      <img
                        src={playStoreButtonImage}
                        width="100%"
                        style={{ maxWidth: 200 }}
                      />
                    </a>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item md={3} />
              <Grid item md={6} sx={{ mb: 0 }}>
                <MKBox
                  my={2}
                  py={2}
                  component="img"
                  width="100%"
                  src={featureImage}
                />
              </Grid>
              <Grid item md={3} />
            </Grid>
            <Grid container>
              <Grid item md={12} sx={{ mb: 0 }}>
                <MKTypography variant="body1" color="dark" mb={2}>
                  Tempo SlowMo is an ideal practice tool for musicians, dancers,
                  language students and fitness enthusiasts. Using technology
                  developed for our Chord Detector and Chord Tutor apps, Tempo
                  SlowMo’s sleek and simple design helps you practice playing
                  songs, dance routines or learning a new language by slowing
                  down or speeding up an audio tracks BPM (beats per minute)
                  without affecting its pitch.
                </MKTypography>
                <MKTypography variant="body1" color="dark" mb={2}>
                  Just hit the Music button within the app to reveal the
                  available audio import options. Import an audio file from your
                  iPod music library, Dropbox account or over WiFi network
                  directly to your device. Tempo SlowMo will then load the audio
                  file which you can then play, pause, slow down and speed up to
                  your hearts content.
                </MKTypography>
                <MKTypography variant="body1" color="dark" mb={2}>
                  Tempo SlowMo also has a feature allowing you to drop markers
                  at any point in the track so that you can quickly skip back to
                  that point if there is a particular bit of a track you are
                  trying to master. Special Loop Markers allow you to loop part
                  of a track if you need to master a specific section. Start and
                  End Track Markers allow you to override the point where a
                  track begins and ends.
                </MKTypography>
                <Grid container>
                  <Grid item md={3} />
                  <Grid item md={6} sx={{ mb: 0 }}>
                    <MKBox
                      my={2}
                      py={2}
                      component="img"
                      width="100%"
                      src={phoneImage}
                    />
                  </Grid>
                  <Grid item md={3} />
                </Grid>
                <MKTypography variant="body1" color="dark" mb={2}>
                  As well as being able to import audio files from different
                  sources you can also make your own recording and save it for
                  future use.
                </MKTypography>
                <MKTypography variant="body1" color="dark" mb={2}>
                  Please note that DRM protected audio files are not supported
                  by this app.
                </MKTypography>
                <MKTypography variant="body1" color="dark" mb={2}>
                  List of core features:-
                </MKTypography>
                <List sx={{ listStyleType: "disc", marginLeft: 4 }}>
                  <ListItem sx={{ display: "list-item" }}>
                    Import tracks from music library sync’d to device
                  </ListItem>
                  <ListItem sx={{ display: "list-item" }}>
                    Change tempo while playing between 20% and 250% or original
                    tempo.
                  </ListItem>
                  <ListItem sx={{ display: "list-item" }}>
                    Display BPM value if track is tagged with BPM via idv3 or
                    iTunes metadata.
                  </ListItem>
                  <ListItem sx={{ display: "list-item" }}>
                    Plays in background
                  </ListItem>
                  <ListItem sx={{ display: "list-item" }}>
                    AirPlay support
                  </ListItem>
                  <ListItem sx={{ display: "list-item" }}>
                    Gapless playback (when Playlist option is installed)
                  </ListItem>
                  <ListItem sx={{ display: "list-item" }}>
                    Play track on continuous loop
                  </ListItem>
                  <ListItem sx={{ display: "list-item" }}>
                    loop selected sections of a track
                  </ListItem>
                  <ListItem sx={{ display: "list-item" }}>
                    Export to Dropbox or Email
                  </ListItem>
                  <ListItem sx={{ display: "list-item" }}>
                    Import your tracks your computer over WiFi
                  </ListItem>
                  <ListItem sx={{ display: "list-item" }}>
                    Import your tracks from Dropbox
                  </ListItem>
                  <ListItem sx={{ display: "list-item" }}>
                    Input from devices microphone and slow down/speed up
                    recording
                  </ListItem>
                  <ListItem sx={{ display: "list-item" }}>
                    Import mp3 and m4a email attachments
                  </ListItem>
                </List>
                <MKTypography variant="body1" color="dark" mb={2} mt={2}>
                  List of additional features available via In-App Purchase:-
                </MKTypography>
                <List sx={{ listStyleType: "disc", marginLeft: 4 }}>
                  <ListItem sx={{ display: "list-item" }}>
                    Playlist – create, update, save and load playlists of
                    tracks. BPM lock feature included which enables you to play
                    an entire playlist at the same BPM.
                  </ListItem>
                  <ListItem sx={{ display: "list-item" }}>
                    Pitch Changer – alter the pitch in 0.5 semitone increaments
                    between -12 and +12 semitones.
                  </ListItem>
                  <ListItem sx={{ display: "list-item" }}>
                    BPM Tapper – determine the BPM of your track and then alter
                    the tempo by BPM value.
                  </ListItem>
                  <ListItem sx={{ display: "list-item" }}>
                    Balance Control – change the volume per channel.
                  </ListItem>
                  <ListItem sx={{ display: "list-item" }}>
                    Equaliser Control – isolate specific frequency bands with
                    this 10 band equaliser.
                  </ListItem>
                  <ListItem sx={{ display: "list-item" }}>
                    Remove Adverts – The app is free but contains adverts. Use
                    this option to remove the adverts.
                  </ListItem>
                </List>
                <MKTypography variant="body1" color="dark"></MKTypography>
              </Grid>
            </Grid>
          </Container>
        </MKBox>
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <HomeFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default TempoSlowMo;
