/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// import Apps from "pages/Presentation/sections/Apps";

import HomeFooter from "examples/Footers/HomeFooter";

import footerRoutes from "footer.routes";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";

// Material Kit 2 React components
import MKTypography from "components/MKTypography";

// // Material Kit 2 React examples
// import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// // Author page sections
// import Profile from "pages/LandingPages/Author/sections/Profile";
// import Posts from "pages/LandingPages/Author/sections/Posts";
// import Contact from "pages/LandingPages/Author/sections/Contact";
// import Footer from "pages/LandingPages/Author/sections/Footer";

// // Routes
// import routes from "routes";

// // Images
import featureImage from "assets/images/cdFeature.png";
import phoneImage from "assets/images/cdPhone.png";
import appStoreButtonImage from "assets/images/appStoreButtonImage.png";

function ChordDetector() {
  return (
    <>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: 8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) =>
            rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <MKBox
          display="flex"
          alignItems="center"
          borderRadius="xl"
          my={2}
          py={2}
        >
          <Container>
            <Grid container spacing={2} alignItems="center">
              <Grid item md={10} sx={{ mb: 0 }}>
                <MKTypography variant="h2" color="dark">
                  Chord Detector
                </MKTypography>
                <MKTypography variant="h5" color="dark" opacity={0.8}>
                  Practice At Your Own Pace
                </MKTypography>
              </Grid>
              <Grid item md={2} sx={{ mb: 0 }}>
                <a href="http://itunes.apple.com/gb/app/chord-detector/id420873903?mt=8">
                  <img
                    src={appStoreButtonImage}
                    width="100%"
                    style={{ maxWidth: 200 }}
                  />
                </a>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item md={2} />
              <Grid item md={8} sx={{ mb: 0 }}>
                <MKBox
                  my={2}
                  py={2}
                  component="img"
                  width="100%"
                  src={featureImage}
                />
              </Grid>
              <Grid item md={2} />
            </Grid>
            <Grid container>
              <Grid item md={12} sx={{ mb: 0 }}>
                <MKTypography variant="body1" color="dark" mb={2}>
                  Chord Detector is aimed at musicians of any ability to help
                  you learn to play the tracks on your music library by
                  automatically detecting the chords within the track.
                </MKTypography>
                <MKTypography variant="body1" color="dark" mb={2}>
                  Here is what a few of our users say:-
                </MKTypography>
                <MKTypography variant="body1" color="dark" mb={2}>
                  ★★★★★ “Really nice app…. really accurate on the chords….
                  totally the new guitarist best friend!”
                </MKTypography>
                <MKTypography variant="body1" color="dark" mb={2}>
                  ★★★★★ “This is the best thing I’ve ever seen or used when it
                  comes to guitar help. I play rhythm and don’t know a lot of
                  complicated harder chords. This solves that problem and now I
                  can play almost anything I want!!!!”
                </MKTypography>
                <MKTypography variant="body1" color="dark" mb={2}>
                  ★★★★★ “I just run Ten Years Afters’ I’d Love To Change The
                  World through it and could play it instantly. That saved me
                  some time and that is welcome because that means more guitar
                  playing time.”
                </MKTypography>
                <MKTypography variant="body1" color="dark" mb={2}>
                  ★★★★★ “A really handy tool to work out song chords. Yes, it
                  does work!”
                </MKTypography>
                <MKTypography variant="body1" color="dark" mb={2}>
                  With our updated Chord Detection engine its now quicker and
                  easier to get the most accurate results possible (up to 95% in
                  ideal conditions). Chord Detector can recognise many different
                  chord types including major, minor, augmented, dimimished,
                  diminished7, dominant7 and minorMajor7.
                </MKTypography>
                <Grid container>
                  <Grid item md={3} />
                  <Grid item md={6} sx={{ mb: 0 }}>
                    <MKBox
                      my={2}
                      py={2}
                      component="img"
                      width="100%"
                      src={phoneImage}
                    />
                  </Grid>
                  <Grid item md={3} />
                </Grid>
                <MKTypography variant="body1" color="dark" mb={2}>
                  To get started just select one of your iPod library tracks(†)
                  from within the Chord Detector app and within minutes you will
                  be presented with a view of the detected chords contained
                  within that track.
                </MKTypography>
                <MKTypography variant="body1" color="dark" mb={2}>
                  You have the option to view the detected chords in two
                  different forms, scrolling and tabular. In both of these views
                  you are able to play the track and the chords will move or be
                  highlighted as the song is playing.
                </MKTypography>
                <MKTypography variant="body1" color="dark" mb={2}>
                  You can now start to view the chords of a track when analysis
                  is still ongoing. Also you can queue up tracks to be
                  processed, so while you are learning to play one song the
                  chords are being generated for your other tracks in the
                  background.
                </MKTypography>
                <MKTypography variant="body1" color="dark" mb={2}>
                  The following controls are available on the chord view
                  screens:-
                </MKTypography>
                <List sx={{ listStyleType: "disc", marginLeft: 4 }}>
                  <ListItem sx={{ display: "list-item" }}>
                    Play/Pause – play or pause the track.
                  </ListItem>
                  <ListItem sx={{ display: "list-item" }}>
                    Rewind 30s – rewind the track by 30 seconds.
                  </ListItem>
                  <ListItem sx={{ display: "list-item" }}>
                    Forward 30s – forward the track by 30 seconds.
                  </ListItem>
                  <ListItem sx={{ display: "list-item" }}>
                    Tempo Adj. – slow down the track without affecting the pitch
                    by a variable amount down to 1/4 of its original speed.
                  </ListItem>
                  <ListItem sx={{ display: "list-item" }}>
                    Capo Adj. – transpose the chords according to which fret you
                    want your capo on
                  </ListItem>
                </List>
                <MKTypography variant="body1" color="dark" mb={2} mt={2}>
                  † The following audio file formats are supported, MP3, AAC,
                  WAV and AIFF. DRM protected content is not supported. Tracks
                  must by sync’d to your device.
                </MKTypography>

                <MKTypography variant="body1" color="dark"></MKTypography>
              </Grid>
            </Grid>
          </Container>
        </MKBox>
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <HomeFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default ChordDetector;
